import * as React from 'react';

const AboutPage = () => {
  return (
    <main>
      <title>About Me</title>
      <h2>About Me</h2>
      <p>Something about me.</p>
    </main>
  );
};

export default AboutPage;
